//*********************
//    Flip Cards CSS
//*********************

.box {
  background: #1f1b34;
  h1 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 3%;
    font-family: Clip;
    text-align: center;
    font-size: 3rem;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
    color: #ffe6ff;
    @media screen and (max-width: 960px) {
      font-size: 2rem;
      margin-bottom: 5%;
    }
  }
}

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%;
  @media screen and (max-width: 960px) {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.flipBox {
  display: flex;
  justify-content: space-around;
  height: 30vh;
  width: 30%;
  position: relative;
  padding: 0;
  margin-bottom: 5%;
  -webkit-perspective: 1000;
  perspective: 1000;

  &:hover {
    .back {
      transform: rotateY(0deg);
      z-index: 1;
    }

    .front {
      transform: rotateY(180deg);
      z-index: -1;
    }
  }

  .back,
  .front {
    position: relative;
    box-shadow: 0px 0px 20px 3px black;
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    flex: 0 0 100%;
    -webkit-transition: all 1s cubic-bezier(0.5, 1, 0.5, 1);
    transition: all 0.7s cubic-bezier(0.5, 1.3, 0.5, 1.3);
    transform-style: preserve-3d;
    background-size: cover;
    background-position: center;
  }

  .back {
    background-color: #cecece;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 30vh;
    z-index: -1;
    transform: rotateY(-180deg);
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 30px;
      height: 30px;
      border: 15px solid transparent;
      border-bottom-color: white;
      border-left-color: white;
      max-height: 100%;
      max-width: 100%;
    }
  }

  .front {
    z-index: 1;
    overflow: hidden;
    .content {
      font-size: 2rem;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 30px;
      height: 30px;
      border: 15px solid #cecece;
      border-bottom-color: white;
      border-right-color: white;
    }
  }

  .content {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    // -webkit-transform: translateZ(50px);
    // transform: translateZ(50px);
    // text-shadow: 0px 0px 2px black;
    h2 {
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.808);
      font-size: 1.5rem;
      font-weight: bold;
      text-transform: uppercase;
      position: absolute;
      color: rgb(194, 16, 93);
    }
    h3 {
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.808);
      font-size: 1rem;
      text-transform: uppercase;
      position: absolute;
      color: rgb(194, 16, 93);
      &:hover {
        background-color: rgb(194, 16, 93);
        color: white;
        cursor: pointer;
      }
    }
    img {
      margin-left: 0px;
      max-height: 100%;
    }
  }
}

@media screen and (max-width: 960px) {
  .container {
    flex-direction: column;
  }
  .flipBox {
    height: 25vh;
    width: 100%;
  }
  .content {
    h2 {
      text-align: center;
    }
  }
  .box {
    padding-top: 5%;
    padding-bottom: 10%;
  }
}
