/* -------
    Contact  CSS
       ------- */

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background:  #161325;
  padding-top: 5%;
  padding-bottom: 5%;
}

.row{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.map {
  border: 0;
  height: 30rem;
  width: 50%;
  padding-left: 10%;
}

.contact {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 30rem;
  padding-left: 5%;
  padding-bottom: 5%;
  padding-right: 8%;
  h1 {
    color:rgb(189, 54, 112);
    font-size: 2rem;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 12px;
  }
  h4 {
    color: white;
    font-size: 1rem;
    font-style: italic;
    font-weight: bold;
    padding-bottom: 1.5%;
  }
  p {
    color: white;
    font-size: 1rem;
    line-height: 18px;
  }
}
.form {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 960px) {
  .container {
    height: 100%;
    flex-direction: column;
    h1 {
      font-size: 2.5rem;
      text-align: center;
    }
  }

  .row{
    flex-direction: column-reverse;
  }

  .map {
    width: 100%;
    padding-left: 0%;
  }

  .contact {
    height: 100%;
    width: 100%;
    padding-top: 5%;
    padding-right: 5%;
    justify-content: center;
    align-items: center;

    p {
      text-align: center;
      font-size: 1rem;
      line-height: 20px;
    }
    h4 {
      font-size: 1.2rem;
      text-align: center;
      padding-bottom: 5%;
      padding-top: 5%;
    }
  }
}
