.container {
  width: 100%;
  height: 100%;
  padding-top: 12vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #161325;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  h1 {
    text-transform: uppercase;
    font-size: 3.5rem;
    padding-top: 2%;
    padding-bottom: 2%;
  }
}

.sign {
  color: #947094;
  animation: shine 4s forwards, flicker 5s infinite;
  font-family: Clip;
  text-align: center;
  text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
    -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
    0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  padding-bottom: 3%;
  .left {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 2%;
    padding-left: 5%;
    img {
      max-height: 400px;
      max-width: 80%;
      margin-left: 0px;
      border-radius: 20px;
      &:hover {
        transform: scale(1.01);
        transition: 0.55s cubic-bezier(0.6, 0.2, 0.1, 1) 0s,
          opacity 0.55s cubic-bezier(0.6, 0.2, 0.1, 1) 0s;
      }
    }
  }
  .right {
    width: 60%;
    height: 100%;
    padding-left: 2%;
    padding-right: 15%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    p {
      color: #ffe6ff;
      font-size: 1rem;
    }

    .text {
      background: linear-gradient(to right, #dc1fa0ad, #e8063700);
      width: 90%;
      padding-left: 1%;
      margin-bottom: 1%;
      h1 {
        display: inline;
        font-size: 2.5rem;
        font-weight: bold;
        color: white;
        padding-bottom: 0px;
        margin-bottom: 0px;
        padding-top: 0px;
        text-align: left;
      }
    }
  }
}

.wrapper2 {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  text-align: center;
  p {
    color: #ffe6ff;
  }
  h2 {
    color: #ffe6ff;
    background: linear-gradient(to bottom right, #b61fdc, #e80638);
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.subwrapper2 {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .socialicons {
    color: #fff;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    margin-bottom: 1rem;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 2%;
    padding-right: 2%;
    font-size: 20px;
    background: linear-gradient(to bottom right, #b61fdc8e, #e80637f5);
    width: 45%;
    display: flex;
    text-align: left;
    justify-content: space-around;
    border-radius: 10px;
    p {
      margin-bottom: 0px;
    }
    .iconLeft {
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .iconsRight {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
    i {
      padding-top: 7px;
      font-size: 35px;
      color: white;
    }
    h4 {
      font-size: 1.5rem;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 0px;
    }
    &:hover {
      transform: scale(1.01);
    }
  }
}

.btns {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-right: 2%;
    border: 1px solid white;
    color: white;
  }
}

@media screen and (max-width: 960px) {
  .container {
    height: 100%;
    padding-left: 2%;
    padding-right: 2%;
    h1 {
      font-size: 3rem;
    }
  }
  .content {
    height: 100%;
    flex-direction: column;
    .left {
      justify-content: center;
      width: 100%;
      padding-right: 0%;
      padding-left: 0%;
      img {
        max-width: 100%;
        border-radius: 0px;
      }
    }
    .right {
      padding-top: 5%;
      width: 100%;
      padding-left: 0%;
      padding-right: 0%;
      .text {
        width: 100%;
        padding-left: 5%;
        h1 {
          font-size: 2rem;
          display: block;
        }
      }
    }
    p {
      padding-left: 2%;
      padding-right: 2%;
    }
  }

  .wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .wrapper2 {
    max-width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .subwrapper2 {
    .socialicons {
      width: 100%;
    }
  }
}

@keyframes shine {
  0% {
    color: #6b1839;
    text-shadow: none;
  }
  100% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}
