.arrow {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.arrow span {
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 5px solid rgb(194, 16, 93);
  border-right: 5px solid rgb(194, 16, 93);
  transform: rotate(45deg);
  animation: animate 2s infinite;
  cursor: pointer;
}
.arrow span:nth-child(2) {
  animation-delay: -0.2s;
  cursor: pointer;
}
/* .arrow span:nth-child(3){
    animation-delay: -0.4s;
    cursor: pointer;
} */
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

@media screen and (max-width: 960px) {
  .arr {
    display: none;
  }
}
