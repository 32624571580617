/* -------
    PRODUCT CSS
       ------- */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 5%;
  background: #1f1b34;
}

.container h3 {
  margin-bottom: 30px;
  font-family: Clip;
  text-align: center;
  font-size: 3rem;
  color: #ffe6ff;
  text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
    -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
    0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
 
  
}

.title {
  margin: 5%;
  font-size: 2.5rem;
  font-family: Clip;
  /* font-weight: bold; */
  letter-spacing: 0em;
  text-align: left;
  color: rgb(194, 16, 93);
}

.text {
  width: 50%;
  margin-left: 5%;
  margin-right: 5%;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 253, 253, 1);
}

.container3 {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container3 a {
  background: none;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid rgb(194, 16, 93);
  color: white;
  text-decoration: none;
}

.container3 a:hover {
  color: white;
  background: rgb(194, 16, 93);
  border: 1px solid rgb(194, 16, 93);
}

@media screen and (max-width: 960px) {
  .title{
    margin: 0%;
    font-family: Roboto;
    font-size: 2rem;
    font-style: normal;
    letter-spacing: 0em;
    text-align: center;
    color: rgb(194, 16, 93);
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .container h3{
    font-size: 2rem;
  }

  .text {
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 1.125rem;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(255, 253, 253, 1);
  }
}
