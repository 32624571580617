body {
  background: #161325;
}

@mixin black-gradient {
  background: linear-gradient(
    to right,
    rgb(22, 19, 37) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

$animationSpeed: 30s;

// Animation
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}

// Styling
.sliderCar3 {
  background: #161325;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-top: 2%;
  padding-bottom: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1{

    font-family: Clip;
    font-size: 3rem;
    color: #ffe6ff;
    // font-weight: bold;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
    -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
    0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
    @media screen and (max-width: 960px) {
      font-size: 2rem;
      text-align: center;
      padding-top: 5%;
      padding-bottom: 5%;
    }

  }

  &::before,
  &::after {
    @include black-gradient;
    content: "";
    height: 100%;
    position: absolute;
    width: 100px;
    z-index: 1;
    @media screen and (max-width: 960px) {
      width: 0px;
    }
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

	.slide-track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		width: 100%;
	}

  .slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // height: 100%;
    width: 350px;
    margin-right: 5%;
    cursor: pointer;
    h3 {
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      color: white;
      text-transform: uppercase;
    }
    p {
      color: white;
      text-transform: uppercase;
			font-size: 1rem;
			margin-bottom: 0px;
    }
    img {
      margin-left: 0px;
    }
    &:hover{
      outline: none;
    }
  }
}

.boton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
	padding-top: 3%;
  padding-bottom: 3%;
  .btn {
    font-size: 1.125rem;
    color: white;
    padding: 20px;
    border: 1px solid white;
    &:hover {
      text-decoration: none;
      background-color: rgb(189, 54, 112);
      color: white;
    }
  }
}
