@font-face {
  src: url(/src/assets/Clip.ttf);
  font-family: Clip;
}

.gallery-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50vh;
}

.item {
  flex: 1;
  height: 50%;
  transition: flex 0.5s ease;
}
.item:hover {
  flex: 6;
}

.item > h1 {
  bottom: 0;
  writing-mode: vertical-rl;
  text-transform: uppercase;
  transform: rotate(180deg);
}

.item-1 {
  background-color: rosybrown;
}

.item-2 {
  background-color: rgb(183, 188, 143);
}

.item-3 {
  background-color: rgb(152, 188, 143);
}

.item-4 {
  background-color: rgb(143, 188, 181);
}

.item-5 {
  background-color: rgb(188, 143, 178);
}

a:hover {
  color: rgb(194, 16, 93);
}


