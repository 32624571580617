/* -------
    Footer CSS
       ------- */

.container {
  height: 100%;
  width: 100%;
  position: relative;
  background: #1f1b34;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2%;
  padding-bottom: 2%;
  img {
    margin-left: 0%;
  }
}

.text {
  padding-top: 3rem;
  padding-left: 3rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 41px;
  color: white;
}

.logo {
  height: 8vh;
  cursor: pointer;
  margin-bottom: 20%;
}

.row {
  display: flex;
  justify-content: space-around;
  width: 80%;
  flex-wrap: wrap;
  h5 {
    color: rgb(194, 16, 93);
    text-align: left;
  }
  ul {
    padding-left: 0%;
    li {
      color: white !important;
      &:hover {
        color: rgb(194, 16, 93) !important;
        transform: scale(1.03);
      }
    }
  }
}

.list {
  line-height: 10px;
}

.social {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 5%;
  a {
    color: white;
    &:hover {
      transform: scale(1.2);
      color: white !important;
    }
  }

}

.copyright {
  height: 7vh;
  background-color: rgb(194, 16, 93);
  color: white;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 960px) {
  .container {
    height: 100%;
    padding-top: 10%;
    padding-bottom: 10%;
  }

  .logo {
    height: 6vh;
  }

  .row {
    padding-left: 0%;
    padding-right: 0%;
    text-align: center;
    h5 {
      font-size: 1.2rem;
      text-align: center;
    }
    ul {
      padding: 0px 0px 20px 0px;
      li {
        font-size: 1rem;
      }
    }
  }

  .copyright {
    height: 6vh;
    font-size: 0.6rem;
  }
}
