.container{
  height: 50vh;
  background: red;
  width: 100%;
  .card{
    width: 100%;
    padding-left: 10%;
    .inside{
      border: 1px solid black;
      height: 40vh;
      background: white;
      width: 70%;
    }
  }
}

