/* -------
    Navbar CSS
       ------- */

.container {
  height: 12vh;
  position: fixed;
  background-color: none;
  z-index: 2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  background: none;
  box-shadow: 0px 0px 0px 0px;
}

.items {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  font-size: 1.26rem;
  .btn{
    font-size: 1rem;
    border: 1px solid white;
    color: white;
    padding-top: 1%; 
    padding-bottom: 1%; 
    padding-left:2%;
    padding-right:2%;
    &:hover{
      background-color: rgb(194, 16, 93);
      color:white
    }
  }
  a {
    color: white;
    text-decoration:none;
  }
  p:hover {
    color: rgb(194, 16, 93);
    transform: scale(1.05);
  }
}

.logo {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    margin-left: 0px;
    height: 7vh;
    cursor: pointer;
  }
}
.active {
  color: rgb(194, 16, 93)!important;
  border-bottom: 1px solid rgb(194, 16, 93);
  padding-bottom: 5%;
}


@media screen and (max-width: 960px) {
  .container {
    padding-left: 0%;
    padding-right: 0%;
  }
  .logo {
    width: 50%;
    img {
    height: 5vh;
    justify-content: flex-end;
  }
}

  .items {
    width: 50%;
    display: none;
  }
}
