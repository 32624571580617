//*********************
//  OurServices  SCSS
//*********************

.container {
  height: 100%;
  width: 100%;
  padding-top: 12vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  background: #161325;
  /* background: linear-gradient(#fffafa, #0c0000); */
  h1 {
    font-size: 3rem;
    font-weight: bold;
    color: rgb(194, 16, 93);
    padding-bottom: 2%;
    word-wrap: break-word;
  }
}

.content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 5%;

  img {
    margin-left: 0px !important;
    border: 0;
    height: 40vh;
    width: 50%;
    padding-left: 0%;
    padding-right: 0%;
  }
  .text {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 40vh;
    padding-left: 5%;
    h2 {
      font-size: 1.7rem;
      font-weight: bold;
      color: rgb(194, 16, 93);
    }
    p {
      font-style: italic;
      font-size: 1.3rem;
      color: white;
      word-wrap: break-word;
    }
    a {
      background: none;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      border: 1px solid rgb(194, 16, 93);
      color: white;
      text-decoration: none;
      &:hover {
        color: white;
        background: rgb(194, 16, 93);
        border: 1px solid rgb(194, 16, 93);
      }
    }
  }
}

.content2 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 5%;
  img {
    margin-left: 0px !important;
    border: 0;
    height: 40vh;
    width: 50%;
    padding-left: 0%;
    padding-right: 0%;
  }
  .text {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 40vh;
    padding-left: 0%;
    padding-right: 5%;
    h2 {
      font-size: 1.7rem;
      font-weight: bold;
      color: rgb(194, 16, 93);
    }
    p {
      font-style: italic;
      font-size: 1.3rem;
      color: white;
      word-wrap: break-word;
    }
    a {
      background: none;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      border: 1px solid rgb(194, 16, 93);
      color: white;
      text-decoration: none;
      &:hover {
        color: white;
        background: rgb(194, 16, 93);
        border: 1px solid rgb(194, 16, 93);
      }
    }
  }
}

.form {
  width: 90%;
}

@media screen and (max-width: 960px) {
  .container {
    flex-direction: column;
    height: 100%;
    h1 {
      font-size: 2.2rem;
      padding-bottom: 0%;
    }
  }

  .content {
    flex-direction: column;
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom:10%;
    img {
      height: 30%;
      width: 100%;
      padding-left: 0%;
      padding-bottom:5%;
    }
    .text {
      width: 100%;
      height: 100%;
      padding-left: 0%;
      padding-right: 5%;
      h2 {
        font-size: 1.5rem;
      }
      p {
        font-size: 1rem;
      }
    }
  }
  .content2 {
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom:10%;
    img {
      height: 30%;
      width: 100%;
      padding-left: 0%;
      padding-bottom:5%;
    }
    .text {
      width: 100%;
      height: 100%;
      padding-left: 0%;
      padding-right: 5%;
      h2 {
        font-size: 1.5rem;
      }
      p {
        font-size: 1rem;
      }
    }
  }
}
