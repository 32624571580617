input {
  display: none;
}

.open {
  background-color: white;
  width: 24px;
  height: 4px;
  display: none;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
}

@media screen and (max-width: 960px) {
  .open {
    display: block;
  }
}

.open:before {
  content: "";
  background-color: white;
  width: 24px;
  height: 4px;
  display: block;
  border-radius: 2px;
  position: relative;
  top: -8px;
  transform: rotate(0deg);
  transition: all 0.3s ease;
}

.open:after {
  content: "";
  background-color: white;
  width: 24px;
  height: 4px;
  display: block;
  border-radius: 2px;
  position: relative;
  top: 4px;
  transform: rotate(0deg);
  transition: all 0.3s ease;
}

.menuOpen {
  width: 24px;
  height: 20px;
  display: block;
  padding: 15px;
  cursor: pointer;
  float: right;
}

.menuOpen:hover .open:before {
  top: -9px;
}

.menuOpen:hover .open:after {
  top: 5px;
}

.menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #1f1b34;
 padding-left: 5%;
 padding-right: 5%;
}

.menu label {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 20px;
  background-size: 100%;
  cursor: pointer;
}

.menu .menuContent {
  position: relative;
  top: 20%;
  font-size: 30px;
  text-align: center;
  padding-bottom: 10px;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
}

.menu ul li a {
  display: block;
  color: white;
  text-decoration: none;
  transition: color 0.2s;
  font-family: Trebuchet MS;
  text-transform: uppercase;
  padding: 10px 0;
}

.menu ul li a:hover {
  color: rgb(194, 16, 93);
}



.menuEffects {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
}

.menuEffects ul {
  transform: translateY(0%);
  transition: all 0.5s;
}

#menuToggle:checked ~ .menuEffects {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s;
}

#menuToggle:checked ~ .menuEffects ul {
  opacity: 1;
}

#menuToggle:checked ~ .menuOpen .open {
  background-color: transparent;
}

#menuToggle:checked ~ .menuOpen .open:before {
  content: "";
  background-color: white;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

#menuToggle:checked ~ .menuOpen .open:after {
  content: "";
  background-color: white;
  transform: rotate(-45deg);
  position: relative;
  top: 0;
  right: 0;
  z-index: 1;
}

#menuToggle:not(:checked) ~ .menuEffects ul {
  transform: translateY(-30%);
}


.socialMedia {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 10%;
}

.socialMedia   a {
  color: white;
  
}

.socialMedia a:hover {
  transform: scale(1.2);
  color: white !important;
}