/* -------
    AboutUs  SCSS
       ------- */

.container {
  height: 100%;
  padding-top: 12vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #161325;
  padding-left: 5%;
  padding-right: 5%;
  h1 {
    font-size: 3rem;
    font-weight: bold;
    color: rgb(194, 16, 93);
    padding-bottom: 2%;
    word-wrap: break-word;
  }
}

.content {
  position: relative;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 2%;
  padding-bottom: 5%;
  img {
    width: 500px;
    height: 300px;
    margin-left: 0px;
  }
}

.text {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 350px;
  position: relative;
  padding-left: 5%;

  p {
    font-size: 1.2em;
    color: white;
    word-wrap: break-word;
  }
}


.about {
  background: #1f1b34;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 2%;
  padding-bottom: 5%;
  border-radius: 20px;
  margin-bottom: 5%;
  h1 {
    color: rgb(194, 16, 93);
    font-size: 1.7rem;
  }
  p {
    text-align: center;
    font-size: 1.2rem;
    font-style: italic;
    color: white;
  }
}

.about2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  flex-wrap: wrap;
  h1 {
    color: rgb(194, 16, 93);
    font-size: 1.7rem;
    padding-bottom: 5%;
  }
  p {
    text-align: center;
    font-size: 1.2rem;
    font-style: italic;
    color: white;
  }
}

@media screen and (max-width: 960px) {

  .container {

    padding-left: 0%;
    padding-right: 0%;
    h1 {
      font-size: 2.2rem;
    }
  }

  .content {
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .text {
    text-align: center;
    width: 100%;
    height: 100%;
    padding-top: 5%;
    padding-left: 0%;
    padding-bottom: 0%;
    p {
      font-size: 1.1rem;
    }
  }

  .about {
    height: 100%;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
    // p {
    //   text-align: center;
    //   font-size: 1rem;
    //   font-style: italic;
    //   color: white;
    //   margin-bottom: 0px;
    // }
  }

  .about2 {
    width: 100%;
    h1 {
      padding-bottom: 0%;
      font-size: 1.5rem;
    }
    p{
      font-size: 1rem;
    }
  }
}
