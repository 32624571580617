#containers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-right: 3%;
  padding-left: 3%;
}

.container {
  padding-top: 12vh;
  padding-bottom: 5%;
  background: #161325;
}


h1 {
  font-weight: bold;
  color: #c90a6d;
  text-align: center;
  font-size: 3rem;
  color: rgb(194, 16, 93);
  padding-bottom: 2%;
}

.customSlider {
  opacity: 0;
  display: none;
}
.customSlider + label {
  position: relative;
  top: 35px;
  left: 15px;
  cursor: pointer;
}
.customSlider + label:before,
.customSlider + label:after {
  background: #fff;
  position: absolute;
  content: "";
}
.customSlider + label:before {
  top: -90px;
  left: -60px;
  width: 80px;
  height: 40px;
  background: #ccc;
  border-radius: 20px;
  transition: background 0.75s;
}
.customSlider + label:after {
  height: 34px;
  width: 34px;
  top: -87px;
  left: -55px;
  border-radius: 50%;
  transition: all 0.5s;
}
.customSlider:checked + label:after {
  height: 34px;
  width: 34px;
  top: -87px;
  left: -19px;
  border-radius: 50%;
}
.customSlider:checked ~ .wrapper {
  color: #fff;
  transition: background 0.6s ease;
  background: #31394d;
}
.customSlider:checked ~ .wrapper .topicons i {
  color: #fff;
}
.customSlider:checked ~ .wrapper .profile .name {
  color: #fdfeff;
}
.customSlider:checked ~ .wrapper .profile .title {
  color: #7c8097;
}
.customSlider:checked ~ .wrapper .profile .description {
  color: #fdfeff;
}
.customSlider:checked ~ .wrapper .icon h4 {
  color: #fff;
}
.customSlider:checked ~ .wrapper .icon p {
  color: #666b7d;
}
.wrapper {
  width: 300px;
  height: 480px;
  background: #3e3957a2;
  transition: background 0.6s ease;
  border-radius: 10px;
  /* padding: 10px 10px 10px 10px; */
  padding-left: 1%;
  padding-right: 1%;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
}
.wrapper .topicons i {
  color: #080911;
}
.wrapper .topicons i:nth-of-type(1) {
  float: left;
}
.wrapper .topicons i:nth-of-type(2) {
  float: right;
}
.wrapper .topicons i:nth-of-type(3) {
  float: right;
  padding-right: 0.8em;
}
.wrapper .profile {
  margin-top: 1em;
  position: relative;
}
/* 
.wrapper .profile:after {
  width: 100%;
  height: 1px;
  content: " ";
  display: block;
  margin-top: 0.8em;
  background: #e9eff6;
} */
/* .wrapper .profile .check {
  position: absolute;
  right: 5em;
  bottom: 12.7em;
} */
/* .wrapper .profile .check i {
  color: #fff;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  border-radius: 100%;
  background: linear-gradient(to bottom right, #c90a6d, #ff48a0);
} */
.wrapper .profile .thumbnail {
  width: 250px;
  height: 250px;
  display: flex;
  margin-left: 13px;
  margin-right: 0px;
  margin-bottom: 0px;
  /* object-fit: fill; */
  border-radius: 50%;
  box-shadow: 0 13px 26px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.2);
}

.wrapper .profile .name {
  padding-top: 5%;
  color: white;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}
.wrapper .profile .title {
  color: white;
  font-size: 0.6em;
  font-weight: 300;
  text-align: center;
  padding-top: 0.3em;
  padding-bottom: 0.5em;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
/* .wrapper .profile .description {
  color: #080911;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 1em;
} */
/* .wrapper .profile .btn {
  color: #fff;
  width: 100px;
  height: 30px;
  outline: none;
  border: none;
  display: block;
  cursor: pointer;
  font-weight: 300;
  margin-left: auto;
  margin-right: auto;
  border-radius: 70px;
  box-shadow: 0 13px 26px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.16);
  background: linear-gradient(to right, #dc1fa0ad, #e8063700);
} */
.wrapper .socialicons {
  display: flex;
  /* margin-top: 0.8em; */
  justify-content: space-around;
}
.wrapper .socialicons .icon {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.wrapper .socialicons .icon a {
  color: #fff;
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  border-radius: 30px;
  background: linear-gradient(to bottom right, #b61fdc8e, #e80637f5);
  box-shadow: 0 13px 26px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.2);
}

.wrapper .socialicons .icon i {
  color: #fff;
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  border-radius: 30px;
  background: linear-gradient(to bottom right, #b61fdc8e, #e80637f5);
  box-shadow: 0 13px 26px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.2);
}

.wrapper .socialicons .icon h4 {
  color: white;
  font-size: 1em;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 0.2em;
  text-transform: uppercase;
}
.wrapper .socialicons .icon p {
  color: white;
  font-size: 11px;
}
.concept {
  position: absolute;
  bottom: 25px;
  color: #aab0c4;
  font-size: 0.9em;
  font-weight: 400;
}
.concept a {
  color: rgba(172, 25, 102, 1);
  text-decoration: none;
}

img {
  margin-left: 12%;
}

.profile img:hover {
  transform: scale(1.01);
}

@media screen and (max-width: 960px) {
  .container h1 {
    font-size: 2.2rem;
  }
  #containers {
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 5%;
  }
}
