/* -------
   Home CSS
       ------- */

/* https://i.imgur.com/2daf1aq.png */
.container {
  // background-image: url("https://i.imgur.com/OvGtSrC.png");
  // background-position: center center;
  // background-size: cover;
  background: #161325; 
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.btn {
  margin-top: 20%;
  background: none;
  padding: 20px;
  border: 1px solid white;
  color: white;
  outline: none;
  font-size: 1.125rem;
  &:hover {
    color: white;
    background: rgb(194, 16, 93);
    border: 1px solid rgb(194, 16, 93);
  }
}

.container3 {
  width: 100%;
  height: 100%;
  background: #1f1b34;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: -10%;
  padding-bottom: -10%;
}

.sign {
  width: 100%;
  letter-spacing: 2;
  color: #ffe6ff;
  animation: shine 2s forwards, flicker 3s infinite;
  .fastFlicker {
    animation: shine 2s forwards, blink 10s 1s infinite;
  }
  h2 {
    font-family: Clip;
    text-align: center;
    font-size: 5rem;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
    @media screen and (max-width: 960px) {
      font-size: 4rem;
      text-align: center;
    }
  }
}

// .flicker {
//   animation: shine 2s forwards, blink 3s 2s infinite;
// }

@media screen and (max-width: 960px) {
  .container {
    height: 100vh;
    padding-left: 0%;
    padding-right: 0%;
    a {
      padding-top: 10%;
    }
  }

  .btn {
    margin-top: 2%;
    background: none;
    padding: 10px;
    border: 1px solid white;
    color: white;
    outline: none;
    font-size: 1.125rem;
  }
}

@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }
  28%,
  33% {
    color: #ff65bd;
    text-shadow: none;
  }
  82%,
  97% {
    color: #ff2483;
    text-shadow: none;
  }
}

@keyframes shine {
  0% {
    color: #6b1839;
    text-shadow: none;
  }
  100% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}
